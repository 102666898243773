*, body, html {
  margin: 0;
  padding: 0;
}

body{
  background-color: #F5F5F5;
  background-image: radial-gradient(#1B1B1E 1px, transparent 0);
  background-size: 40px 40px;
  position: relative;
}

.App{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 25px;
}

.content{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.cotent_box{
  position: relative;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border: 2px solid rgba(255, 255, 255, 1);
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  min-width: 25%;
}

.title{
  font-size: 1.4rem;
  text-align: center;
}

.input_box{
  border: 1px solid #adb5bd;
  border-radius: 10px;
  overflow: hidden;
}

.input_box_top{
  padding: 10px;
  background-color: white;
}

.input_box_bottom{
  background-color: #dee2e6;
  border-top: 1px solid #adb5bd;
}

.inputField{
  background-color: #dee2e6;
  border: none;
  padding: 10px;
  border-bottom: 10px;
}

.inputField:focus{
  background-color: #dee2e6;
  border: none; /* Remove border when focused */
  padding: 10px; /* Reset padding if needed */
  outline: none; /* Remove focus outline */
}

.check_btn{
  border-radius: 10px;
  background-color: #fca311;
  color: white;
  padding: 10px;
  min-width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.check_btn:hover{
  cursor: pointer;
  scale: 1.05;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
}

.certificate_details{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.details_item{
  flex: 1;
  border: 1px solid #adb5bd;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  padding: 0;
}

.item_label{
  padding: 10px;
  background-color: #dee2e6;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;  
  flex-direction: column;
}

.item_label p{
  padding: 0;
  margin: 0;
}

.item_value{
  flex: 1;
  flex-direction: column;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
}

.response_positive_msg{
  background-color: #20bf55;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 10px;
}

.response_negative_msg{
  background-color: #e9ecef;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  gap: 10px;
}